@import "~@duetds/css/lib/duet.min.css";
@import "~@duetds/tokens/lib/tokens.scss";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
#root {
  min-height: 100%;
  height: 100%;
}

body {
  // min-height: 100% does not work on body as it's dependent on width of the parent element.
  height: 100%;
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.questionnaire-card {
  width: min(90vw, 34.0625rem); // TODO: min needed?
}

.questionnaire-title-container {
  padding: 1.5rem;
}

.report-card {
  width: 30rem;
}

.report-banner-text {
  max-width: 90%;
}


.footer{
    margin-top: auto;
    bottom: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  &-columns-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  &-news-leads {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    // gap: 20px;
  }

  &-columns-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    justify-items: center;
  }
}

.pie-card {
  width: min(45vw, 16rem);
}

.risks-grid-item {
  margin-bottom: 8px;
  font-size: $font-size-medium;
  color: $color-primary-dark;
}

.risks-link {
  margin-left: 20px;
}

.news-leads-container {
  grid-column: 2 / span 2;
}

.news-paragraph {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.news-photo {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.gap {
  gap: 2rem;
}

.border {
  background-color: green;
  width: 100%;
  border-top: 1px solid black;
}

$breakpoint-columns-one: 1200px;
@media (max-width: $breakpoint-columns-one) {
  .grid-columns-4 {
    grid-template-columns: 1fr 1fr;
  }
  .grid-columns-3 {
    grid-template-columns: 1fr 1fr;
  }
  .grid-news-leads {
    grid-template-columns: auto 1fr;
  }
  .news-leads-container {
    grid-column: 2 / span 1;
  }
  .grid-columns-2 {
    grid-template-columns: unset;
  }
  .questionnaire-card {
    width: 44vw;
  }
  .report-card {
    width: 38vw;
  }
}

@media (max-width: 800px) {
  .grid-columns-4 {
    grid-template-columns: 1fr;
  }
  .grid-columns-3 {
    grid-template-columns: 1fr;
  }
  .grid-news-leads {
    grid-template-columns: 1fr;
  }
  .news-leads-container {
    grid-column: 1 / span 1;
  }
  .grid-columns-2 {
    grid-template-columns: unset;
  }
  .grid {
    grid-template-columns: unset;
  }
  .news-photo {
    border-radius: 4px;
  }
  .pie-card {
    width: unset;
  }
  .questionnaire-card {
    width: 90vw;
  }
  .report-card {
    width: 80vw;
  }
  .report-banner-text {
    max-width: 90%;
  }
}

.skeletor {
  filter: blur(8px);
}

.news-grid {
  display: grid;
  height: 320px;
  grid-template-rows: auto 0.1fr;
}

.error-message {
  font-size: $font-size-small;
  color: $color-danger;
}

.mobile-toggle-buttons {
  margin: 0.2rem;
}

.pie-info-desktop {
  grid-column: 1 / span 4;
}

.pie-info-pad {
  grid-column: 1 / span 2;
}

.mobile-list-container {
  margin-left: -0.5rem;
}
.span-2{
    grid-column: 1 / 3
}
.center{
    justify-items: center;
    align-items: center;
    justify-self: center;
    border: 1px solid black;
    margin: auto;
    padding: auto;
}

.center-text{
    margin: auto;
    text-align: center;
}

.search-input{
    width: 421px;
}

.rule{
    background-color: #f5f8fa;
    padding: 38px 30px 30px 30px
}
.customer-segment{
    max-width: 45rem;
}

.ruleccc{
    display: flex;
    background-color: #f5f8fa;
    justify-content: center;
}

.rule-or{
    color: white;
    width: 3.5625rem;
    height: 1.5625rem;
    text-align: center;
    border-radius: 0.875rem;
    background-color: #002d54;
    font-size: 1rem;
}
.line{
    flex-grow: 1;
    width: auto;
    height: 1px;
    margin-top: auto;
    margin-bottom: auto;
    border: solid 1px #002d54;
}
.line-vertical{
    flex-grow: 1;
    width: 1px;
    margin-left: auto;
    margin-right: auto;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border: solid 1px #002d54;
}

.rule-and__container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.rule-and{
    color: white;
    margin-left: auto;
    margin-right: auto;
    width: 3.5625rem;
    height: 1.5625rem;
    text-align: center;
    border-radius: 0.875rem;
    background-color: #002d54;
    font-size: 1rem;
}

.bigtext{
    font-weight: 800;
    font-size: 36px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.maxchar{
    display:inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
}
.maxchar:hover{
    text-decoration: underline;
}

.reportlink-row:hover{
    text-decoration: underline;
    cursor: pointer;

    .maxchar{
 text-decoration: underline;
    }


};

.layout{
  min-height: calc(100vh - 363px)
}